@import '../node_modules/antd/dist/antd.css';
@import 'styles/app.post.css';

.header {
  padding: 0 25px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.header,
.header__logo,
.header__nav {
  flex-wrap: wrap;

  @media screen and (min-width: 32rem) {
    line-height: 90px;
    height: 90px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
  }
}

.header__logo,
.header__nav {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.logo {
  width: auto;
  max-width: 100%;
  max-height: 40px;
}

.header,
.nav {
  width: 100%;
}

.nav {
  margin: 0.5rem 1rem 0;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 32rem) {
    justify-content: flex-end;
  }
}

.nav-item {
  font-size: 1rem;
}

.nav-link {
  font-family: var(--ff-alt);
  font-size: 16px;
  font-weight: bold;
  padding: 0 0.5rem;
  color: #414141;
  line-height: 3;

  @media screen and (min-width: 32rem) {
    line-height: 1;
  }
}

a.nav-link + button.nav-link {
  margin-left: 2rem;
}

button.nav-link + button.nav-link {
  margin-left: 1rem;
}

.main {
  flex: 1;
}

.footer {
  background-color: #414141;
  color: #f4f5f6;
  padding: 16px 50px 8px;
}

.footer.bottom-bar {
  padding: 0.25rem 0;
}

.footer-wrap {
  padding: 24px 0;
}

.footer__logo {
  float: left;
}

.form__item-label > label,
.ant-form-item-label {
  line-height: 60px !important;
  height: 60px !important;
  color: #414141 !important;
  font-family: var(--ff-alt) !important;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  padding: 2vh 0;
}

.history__btn {
  width: 100%;
  min-width: 115px;

  @media screen and (min-width: 60rem) {
    width: auto;
  }
}
