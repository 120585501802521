.ElementsApp input,
.InputElement,
.StripeElement {
  display: block;
  max-width: 500px;
  padding: 10px 14px;
  font-family: var(--ff-base);
  font-size: 18px !important;
  padding: 0.75rem 1rem;
  background: #fff;
  box-sizing: border-box;
  width: 100%;
  margin: 0.25rem 0;
  outline: 0;
  height: 50px;
  border-color: rgba(65, 65, 65, 0.125);
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 4px;
  color: #414141;
  appearance: none;
  outline: none;
  margin: 0 0 24px;
}

.StripeElement {
  margin-bottom: 0 !important;

  &--focus {
    outline: 0;
    border-width: 0.125rem;
    border-style: solid;
    border-color: #f16f6e;
    box-shadow: inset 0 0 0 1px #f16f6e;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  &.IdealBankElement,
  &.PaymentRequestButton {
    padding: 0;
  }
}
