* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 18px;
  scroll-behavior: smooth;
}

body {
  line-height: 1.6;
  font-family: var(--ff-base);
  color: var(--primaryDark);
  margin: 0;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  border-width: 0;
  border-top: 1px solid rgba(41, 41, 41, 0.175);
}

a {
  color: var(--white);
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;

  &:hover,
  &:active {
    color: color(var(--pink) tint(30%));
  }
}

.cta-text {
  margin: 0.5rem auto;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: -0.025rem;

  &--link {
    color: white;

    &:hover {
      color: #eaeaea;
    }
  }
}

p,
ul,
li {
  font-size: 1rem;
  color: white;
}
