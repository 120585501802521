.ant {
  &-layout-content {
    flex: 1;
  }

  &-layout-header,
  &-menu-dark {
    background-color: transparent;
  }

  &-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;

    & > .anticon {
      color: white;
    }
  }

  &-input,
  &-select,
  &-input-number {
    min-width: 200px !important;
    width: 325px !important;
  }

  /* only using .ant-input-lg here for specificity */
  &-input.ant-input-lg,
  &-select-selection.ant-select-selection--single {
    &:focus {
      border-width: 2px !important;
      border-width-right: 2px !important;
    }
  }

  &-select-lg {
    font-size: 1rem !important;
  }

  &-form-item {
    margin: 0 0 0.5rem !important;
  }

  &-table-title {
    background-color: white;
    height: 4rem;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 600;
  }

  &-card {
    &-head {
      font-size: 0.875rem !important;

      @media (min-width: 32rem) {
        font-size: 1rem !important;
      }

      &-title {
        font-weight: 600;
        white-space: normal !important;
        padding-right: 1.5rem !important;
      }
    }

    &-body {
      white-space: pre-wrap;
    }

    &-extra {
      align-self: start;
    }
  }

  &-progress-inner {
    background-color: white !important;
  }

  &-spin-text {
    font-size: 1rem;
    margin-top: 1rem;
    color: black;
  }

  &-result-subtitle {
    white-space: pre-line !important;
  }
}
