:root {
  --baseline: 1.6rem;
  --base-fs: 20px;
  --lh: var(--baseline);
  --ff-base: 'Lato', sans-serif;
  --ff-alt: 'Oxygen', sans-serif;
  --ff-base-mono: var(--ff-mono);
  --ff-serif: 'Georgia', 'Athelas', 'Charter', 'Hoefler Text', 'Baskerville', 'Droid Serif', 'Times',
    serif;
  --ff-serif-alt: 'Athelas', 'Georgia', 'Charter', 'Hoefler Text', 'Baskerville', 'Droid Serif',
    'Times', serif;
  --ff-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  --ff-mono: 'Inconsolata', 'Monaco', 'Consolas', 'Andale Mono', 'Bitstream Vera Sans Mono',
    'Courier New', Courier, monospace;
  --fs-xxs: calc(var(--fs) * 0.5);
  --fs-xs: calc(var(--fs) * 0.75);
  --fs-sm: calc(var(--fs) * 0.875);
  --fs: var(--baseline);
  --fs-md: calc(var(--fs) * 1.125);
  --fs-lg: calc(var(--fs) * 1.25);
  --fs-xl: calc(var(--fs) * 1.375);
  --fs-xxl: calc(var(--fs) * 1.625);
  --fw-200: 200;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
  --h1: 2rem;
  --h2: 1.6rem;
  --h3: 1.4rem;
  --h4: 1.2rem;
  --h5: 1rem;
  --h6: 0.9rem;
  --lh: var(--baseline);
  --global-radius: 4px;
  --default-color: var(--white);
  --primary: #f16f6e;
  --primaryDark: #414141;
  --secondary: #224f5f;
  --secondaryLight: #b4d8d8;
  --secondaryLighter: #edf6f7;
  --tertiary: #ecf6f4;
  --accent-color-1: var(--indigo);
  --accent-color-2: var(--violet-dark);
  --accent-color-3: var(--red);
  --success-color: var(--green);
  --danger-color: var(--red);
  --warning-color: var(--orange);
  --border-color: var(--gray);
  --text-color: var(--blackish);
  --link-color: var(--primary);
  --font-color: var(--primary);
  --btn-bg: transparent;
  --btn-color: var(--blue);
  --btn-default-color: var(--white);
  --btn-default-text: var(--blackish);
  --btn-primary-color: var(--primary);
  --btn-primary-text: var(--white);
  --btn-success-color: var(--green);
  --btn-success-text: var(--white);
  --btn-danger-color: var(--red);
  --btn-danger-text: var(--white);
  --btn-warning-color: var(--orange);
  --btn-warning-text: var(--white);
  --border-radius: 3px;
  --border-color: transparent;
  --border-color--focus: rgba(0, 0, 0, 0.125);

  --yellow: #ffdc1c;
  --red: #d0262a;
  --green: #38b44b;
}

@media (min-width: 650px) {
  --base-fs: 16px;
  --h1: 3rem;
  --h2: 2rem;
  --h3: 1.6rem;
  --h4: 1.3rem;
  --h5: 1rem;
  --h6: 0.7rem;
}
